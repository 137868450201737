body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

button:focus-visible,
input:focus-visible,
select:focus-visible {
    outline: none;
}

@media only screen and (min-width: 1024px) {
    #root {
        height: calc(100vh - 160px);
        border-bottom: 1px solid rgb(209 213 219);
    }

    .app-wrapper {
        min-height: calc(100% - 48px);
        height: auto;
    }
}